import React from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../asset/logo.png";

// ------------------------------------

const Footer = () => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const wasteCounter = useSelector((state) => state.counter.value);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <>
      <footer className="w-full flex flex-row h-8 text-slate-200 absolut bottom-0 justify-start items-center px-6 bg-slate-900 text-xs z-10">
        <div className="flex flex-row justify-start items-center ">
          <span onClick={handleLogout} className="text-xl px-2 z-50">
            {user ? "🔓" : "🔐"}
          </span>
          <Link to="/account" className="z-50">
            <span>{user && user.email}</span>
          </Link>
        </div>

        <div>
          <div className={` ${user ? "block" : "hidden"}`}>
            <Link to="/WasteBoard">
              <div className="w-18 h-18 absolute bottom-4 right-4 bg-gray-300 rounded-full  p-4 flex justify-center items-center border-slate-900 border-4">
                <div className="text-4xl">🗑️</div>
                {wasteCounter > 0 && (
                  <div className="absolute bottom-2 right-0 bg-blue-950 text-gray-300 rounded-full h-8 w-8 text-xs flex items-center justify-center border-red-700 border-2 bg-red-700 z-20">
                    {wasteCounter}
                  </div>
                )}
              </div>
            </Link>
          </div>
        </div>
        <div>
          <div className="w-full h-8  text-gray-400 md:flex flex-row items-center justify-center absolute bottom-1 left-0 z-40 hidden">
            ©
            <span className="font-semibold  text-gray-200 ml-1">
              <img src={logo} width={16} height={16} alt="" className="pt-2" />
            </span>
            <span className="text-[10px] text-gray-500 mr-2 pt-3 pl-0.5">
              qar.systems
            </span>
            <span className="text-xs font-light text-gray-200 pt-2 ">2024</span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
