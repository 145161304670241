import React from "react";
import { useParams } from "react-router-dom";
import Slider from "./Slider";
import { findProduct } from "../../lib/find";

// ---------------------------------------------------------------

const RecipeDetail = () => {
  const params = useParams();
  const steps = findProduct(params.recipeId).steps;

  return (
    <div className="max-w-[1000px] mx-auto my-16 p-4">
      <Slider sliderData={steps} />
    </div>
  );
};

export default RecipeDetail;
