import React from "react";
import { Link } from "react-router-dom";
import { findProducts, promotedProducts } from "../../lib/find";

// --------------------------------------------------------------------

const NavLink = ({ item }) => {
  const { url, name } = item;
  const isDisplay = findProducts(name).length > 0 || name === "Aktionen";

  return (
    <Link to={`/groups/${url}`}>
      <div className={` ${isDisplay ? "block" : "hidden"}`}>
        <li className="md:pt-3 mx-1.5 px-1 md:py-0 py-4  w-full text-slate-400 text-center border-b md:border-b-0 border-red-600  md:hover:text-red-600 md:hover:bg-transparent hover:border-b-2 hover:border-red-600 hover:py-1">
          <span className="text-xl mr-2">{item.icon}</span>
          <span className="font-face-gm text-sm">{name.toUpperCase()}</span>
        </li>
      </div>
    </Link>
  );
};

export default NavLink;
