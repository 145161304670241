import React from "react";
import { Link, useParams } from "react-router-dom";
import left from "../../asset/left.svg";
import right from "../../asset/right.svg";
import {
  currNewsIndex,
  findNews,
  goNews,
  lengthNewsData,
} from "../../lib/find";

// ---------------------------------------------------------------

const NewsDetail = () => {
  const params = useParams();
  const currNews = findNews(params.newsId)[0];
  const currIndex = currNewsIndex(currNews.id);

  // NextElement
  function NextElement() {
    if (currIndex === lengthNewsData() - 1) {
      return goNews(0);
    } else {
      return goNews(currIndex + 1);
    }
  }

  // PrevElement
  function PrevElement() {
    if (currIndex === 0) {
      return goNews(lengthNewsData() - 1);
    } else {
      return goNews(currIndex - 1);
    }
  }

  return (
    <div className="max-w-[1000px] md:mx-auto my-16 border-white border rounded-xl mx-4">
      <h1
        className={`${currNews.style} text-2xl flex flex-row justify-between rounded-t-xl p-4`}
      >
        <div>
          <span className="mr-2">{currNews.icon}</span>
          <span>{currNews.topic}</span>
        </div>
        <div className="flex flex-row justify-between">
          <Link to={"/news/" + PrevElement()} className="mr-4">
            <img src={left} alt="prev" />
          </Link>
          <Link to={"/news/" + NextElement()}>
            <img src={right} alt="next" />
          </Link>
        </div>
      </h1>
      <p className="p-4">{currNews.text}</p>
    </div>
  );
};

export default NewsDetail;
