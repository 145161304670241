// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// !!!!!!!!! env Datei anlegen

const firebaseConfig = {
  apiKey: "AIzaSyB8eDhCdpFExtFehvm9F-W8rRNYW-6ildA",
  authDomain: "qar-nordsee.firebaseapp.com",
  projectId: "qar-nordsee",
  storageBucket: "qar-nordsee.appspot.com",
  messagingSenderId: "103219672549",
  appId: "1:103219672549:web:844322d4eb4747c4226b0c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app



