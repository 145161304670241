import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import WasteTr from "./WasteTr";
import { clearWaste } from "../../redux/wasteSlice";
import { clearCounter } from "../../redux/counterSlice";
import SummaryWaste from "./SummaryWaste";
import Keypad from "./Keypad";

// ---------------------------------------------------------------

const WasteTable = () => {
  const [trashcan, setTrashcan] = useState(
    useSelector((state) => state.waste.trashcan)
  );
  const [summaryDisplayed, setSummaryDisplayed] = useState(false);
  const wasteCounter = useSelector((state) => state.counter.value);
  const [editWasteArt, setEditWasteArt] = useState(false);
  const [currInvArtikel, setCurrInvArikel] = useState("");

  const dispatch = useDispatch();
  const switchSummaryDisplayed = () => {
    setSummaryDisplayed(!summaryDisplayed);
  };

  const handleClearWaste = () => {
    dispatch(clearWaste());
    dispatch(clearCounter());
    setTrashcan([]);
    switchSummaryDisplayed();
  };

  const handleEditWasteArt = (index) => {
    setEditWasteArt(true);
    console.log(index);
  };

  return (
    <div className="bg-slate-200 flex flex-col justify-between items-center border-b border-slate-700 w-full pb-8 rounded-b-2xl">
      {!editWasteArt && (
        <table className="w-full">
          <thead>
            <tr className=" bg-slate-700 text-slate-300 p-4 text-xs text-left ">
              <th className=" py-2 px-8 text-center w-1/12 border-r border-white">
                InvArtNr
              </th>
              <th className="py-2 px-4 w-8/12  border-r border-white">Name</th>
              <th className="py-2 px-4 w-2/12 border-r border-white">Menge</th>
              <th className="py-2 px-4 w-1/12">EH</th>
            </tr>
          </thead>

          <tbody>
            {!summaryDisplayed &&
              trashcan.map(function (item, index) {
                return (
                  <WasteTr
                    waste={item}
                    key={index}
                    handleEditWasteArt={handleEditWasteArt}
                    index={index}
                  />
                );
                console.log(item);
              })}

            {summaryDisplayed && <SummaryWaste />}
          </tbody>
        </table>
      )}
      {editWasteArt && <h1 onClick={() => setEditWasteArt(false)}>edit</h1>}

      {wasteCounter > 0 && !editWasteArt && (
        <div className="w-full px-4">
          {!summaryDisplayed && (
            <button
              onClick={() => setSummaryDisplayed(!summaryDisplayed)}
              className="p-2 border rounded-xl border-blue-900 mt-4"
            >
              Zusammenfassung
            </button>
          )}
          {summaryDisplayed && (
            <div className="flex flex-row justify-between items-center w-full px-4">
              <button
                onClick={() => handleClearWaste()}
                className="p-2 px-4 border rounded-xl border-red-300 mt-4 bg-red-700 text-stone-300"
              >
                Alle löschen
              </button>
              <button
                onClick={() => switchSummaryDisplayed()}
                className="p-2 border rounded-xl border-green-300 mt-4 bg-green-800  text-stone-300"
              >
                zurück
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WasteTable;
