import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// ---------------------------------------------------------------

const Card = ({ cardData, width }) => {
  const navigate = useNavigate();
  const classWidth = width === "sm" ? "w-[200px]" : "w-[280px]";

  return (
    <div>
      <div
        className={`${classWidth} h-[416px]  px-4 flex flex-col justify-start items-center bg-white rounded-xl border-blue-900 border-[1px] m-4`}
      >
        <Link className="h-4/5" to={"/recipes/" + cardData.id}>
          <div className="h-2/5 w-11/12 flex justify-center items-center rounded-xl m-4 p-1">
            <img
              src={cardData.src}
              alt="logo"
              className="object-cover overflow-auto rounded-xl h-4/5"
            />
          </div>
          <div className="w-full pt-4 h-2/5 flex flex-col justify-center items-center text-xl font-bold border-t-2 border-blue-900 uppercase text-center">
            <p className="font-face-gm text-blue-900">{cardData.name}</p>
          </div>
        </Link>
        <div className="pt-4 w-11/12 flex flex-row justify-between items-end mb-4 border-t-2 border-blue-900">
          <Link
            className="cursor-pointer w-10 h-10 bg-slate-200 rounded-full border border-red-500 flex justify-center items-center"
            to={"/waste/" + cardData.id}
          >
            🗑️
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
