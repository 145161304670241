import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

// ---------------------------------------------------------------

const Account = () => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const actUrl = useLocation();

  console.log(actUrl);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="max-w-[1000px] mx-auto my-16 p-4">
      <h1 className="text-3xl font-bold py-4">Account</h1>
      <p>User Email: {user && user.email}</p>
    </div>
  );
};

export default Account;
