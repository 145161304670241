import React, { useEffect, useState } from "react";
import { navData } from "../data/navdata";
import Ingriedence from "../components/recipes/Ingriedence";
import Steps from "../components/recipes/Steps";
import AddImage from "../components/addImage/AddImage";

// ---------------------------------------------------------------
// NEUMACHEN
// ---------------------------------------------------------------

const initialstate = {
  name: "",
  src: "",
  group: "Snacks",
  invNumbers: [],
  steps: [
    { id: 1, src: "haloo", desc: "hhh" },
    { id: 2, src: "Bild2", desc: "Foto" },
  ],
  active: true,
  promotion: false,
};

const CreateRecipe = () => {
  const [data, setData] = useState(initialstate);
  const [imgString, setImgString] = useState();
  const { name, group, invNumbers, steps } = data;
  const groups = navData.filter((o) => o.name != "Aktionen");
  const [msgErrors, setMsgErrors] = useState({});

  // InputHandler:
  const handleChange = (e) => {
    const newData = { ...data, [e.target.name]: e.target.value };
    setData(newData);
  };

  const handleIngriedient = (invItems) => {
    const newData = { ...data, invNumbers: invItems };
    setData(newData);
  };

  const handleSteps = (steps) => {};

  const handleImg = (imgData) => {
    const newData = { ...data, src: imgData };
    setData(newData);
    setImgString(imgData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mb-8 mt-16 mx-8  border border-blue-900  text-slate-900 rounded-2xl">
      <div className="w-full p-2 border-b border-blue-900 bg-slate-900 text-slate-300 rounded-t-2xl">
        <div className="px-2 text-2xl">
          <span className="mr-2">🧑🏻‍🍳</span>
          <span className="font-face-gm uppercase text-sm md:text-xl">
            Neues Produkt hinzufügen
          </span>
        </div>
      </div>

      <form className="p-2 bg-slate-300 rounded-b-2xl px-4 text-slate-900 flex flex-col justify-center items-center w-full ">
        <label className="grid md:grid-cols-3 w-full p-2">
          <div className="col-span-1">Produktbild:</div>
          <div className="col-span-2 rounded-xl bg-white flex p-5 justify-center">
            <AddImage handleImg={handleImg} />
          </div>
        </label>
        <label className="grid md:grid-cols-3 w-full p-2">
          <div className="col-span-1">Produktname:</div>
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={data.name}
            onChange={handleChange}
            className={`${
              msgErrors.name ? "bg-red-100 border-red-600 border-2 " : ""
            } p-2 w-full rounded-xl col-span-2`}
          />
        </label>
        <label className="grid md:grid-cols-3 w-full p-2">
          <div className="col-span-1">Produktgruppe:</div>
          <div className="col-span-2 p-2 w-full rounded-xl bg-white px-4">
            <select
              name="group"
              id="group-select"
              onChange={handleChange}
              className="w-full"
            >
              {groups.map((op, index) => (
                <option key={index} value={op.name}>
                  <span>{op.icon}</span>
                  <span> </span>
                  <span>{op.name}</span>
                </option>
              ))}
            </select>
          </div>
        </label>
        {
          // Zutaten:
          // --------- ERROR! -------
        }
        <label className="grid md:grid-cols-3 w-full p-2">
          <div className="col-span-1">Zutaten:</div>
          <div className="col-span-2 w-full rounded-xl bg-white">
            <Ingriedence handleIngriedient={handleIngriedient} />
          </div>
        </label>
        {
          //Arbeitsschritte:
        }
        <label className="grid md:grid-cols-3 w-full p-2">
          <div className="col-span-1">Arbeitsschritte:</div>
          <div className="col-span-2 w-full rounded-xl bg-white">
            <Steps handleSteps={handleSteps} data={data.steps} />
          </div>
        </label>
        {
          //Arbeitsschritte:
        }
        <button className="w-full bg-green-800 text-white p-2 rounded-full mt-4">
          Produkt hinzufügen
        </button>
      </form>
    </div>
  );
};

export default CreateRecipe;
