import { createSlice } from "@reduxjs/toolkit";

// ---------------------------------------------------------------

const initState = {
  trashcan: [],
  wCounter: 0,
};

export const wasteSlice = createSlice({
  name: "waste",
  initialState: initState,
  reducers: {
    addWaste: (state, action) => {
      state.trashcan.push(action.payload);
      state.wCounter += 1;
    },
    clearWaste: (state) => {
      state.trashcan = [];
    },
  },
});

export const { addWaste, clearWaste } = wasteSlice.actions;

export default wasteSlice.reducer;
