import React from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { UserAuth } from "../../context/AuthContext";

// ----------------------------------------------------------------

const Layout = ({ children }) => {
  const { user } = UserAuth();

  return (
    <>
      <div className="w-full flex flex-col h-full">
        <div className="fixed z-20 top-0 start-0 w-full">
          {user ? <Navigation /> : ""}
        </div>
        <div className="mt-8 md:mt-16 pb-8">{children}</div>
        <div className="fixed z-20 bottom-0 start-0 w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
