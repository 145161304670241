import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { increment } from "../redux/counterSlice";
import { addWaste } from "../redux/wasteSlice";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { findInvConversion, findInvNr, findInvUnit } from "../lib/find";
import SearchInvItem from "../components/waste/SearchInvItem";
import CardBack from "../components/waste/CardBack";
import WasteTable from "../components/waste/WasteTable";
import { nanoid } from "@reduxjs/toolkit";

// --------------------------------------------------------------- !!!!

const WasteBoard = () => {
  const [artikel, setArtikel] = useState("");
  const [newWasteInput, setNewWasteInput] = useState(false);
  const { user, logout } = UserAuth();

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleInput = () => {
    dispatch(increment());
  };

  const handleInputWaste = (amount) => {
    dispatch(
      addWaste({
        id: nanoid(),
        user: user.email,
        invArt: findInvNr(artikel),
        invName: artikel,
        invAmount: amount,
        invUnit: findInvUnit(findInvNr(artikel)),
      })
    );
    handleInput();
    handleCancel();
  };

  const handleNewArtikel = (value) => {
    setArtikel(value);
  };

  const handleCancel = () => {
    setArtikel("");
    setNewWasteInput(false);
  };

  return (
    <div className="max-w-full mx-auto my-9 md:my-8 p-4 text-white text-center">
      <div className="mb-8 rounded-2xl border-2 border-slate-800  bg-stone-300 text-slate-700 flex flex-col justify-center items-center md:mx-12">
        {/* Card Header */}
        <div className="flex flex-col md:flex-row md:items-center text-2xl justify-between p-2 px-4  bg-stone-300 rounded-t-2xl border-blue-900 pb-2 w-full">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center">
              <div className="mx-2 rounded-full p-2 border-2 border-red-500 bg-slate-100 h-12 w-12 flex justify-center items-center">
                🗑️
              </div>
              <span onClick={() => history(-1)}>
                <span className="font-face-gm uppercase ">Abschriften</span>
                <span className="h-4 p-2 text-xs text-center rounded-full border flex items-center justify-center px-2 bg-green-800 text-stone-300">
                  zurück
                </span>
              </span>
            </div>
            <span className="text-xs ml-2">
              {!newWasteInput && (
                <div
                  className="flex flex-row justify-between items-center"
                  onClick={() => setNewWasteInput(!newWasteInput)}
                >
                  <span className="mr-2 hidden md:block">
                    Abschrift hinzufügen
                  </span>
                  <span className="h-6 rounded-full border flex items-center px-2 bg-green-500">
                    +
                  </span>
                </div>
              )}
            </span>
          </div>
          <div className="mt-2 md:mt-0">
            {newWasteInput && (
              <div className="w-[280px]">
                <SearchInvItem
                  handleNewArtikel={handleNewArtikel}
                  handleCancel={handleCancel}
                />
              </div>
            )}
          </div>
        </div>

        {/* Card Content */}
        <div className="flex flex-row bg-white-500 text-black w-full items-center justify-between">
          {findInvConversion(artikel) && (
            <CardBack
              currInvArtikel={findInvNr(artikel)}
              handleInputWaste={handleInputWaste}
            />
          )}
        </div>
        <div className="rounded-2xl w-full">
          {!newWasteInput && <WasteTable />}
        </div>
      </div>
    </div>
  );
};

export default WasteBoard;
