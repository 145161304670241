import React, { useState } from "react";

const Steps = ({ handleSteps, data }) => {
  const [isNewStep, setIsNewStep] = useState(false);
  const [steps, setSteps] = useState([]);

  const handleAddStep = () => {
    setIsNewStep(!isNewStep);
  };

  const StepsTable = (stepsdata) => {
    return;
  };

  return (
    <div className="w-full p-2">
      <button
        onClick={() => {
          setIsNewStep(true);
        }}
      >
        +
      </button>
      {isNewStep ? (
        <div>Hallo </div>
      ) : (
        <table className="w-full rounded-full mt-2">
          <thead className="text-left rounded-full border-b border-slate-900 bg-slate-900 text-slate-300 text-sm font-thin">
            <th className="w-2/12 py-2 text-center">Pos</th>
            <th className="w-2/12 text-center">Foto</th>
            <th className="w-6/12 ">Beschreibung</th>
            <th className="w-2/12 pl-1">Edit</th>
          </thead>

          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{item.src}</td>
                  <td>{item.desc}</td>
                  <td>❌ ⬆️ ⬇️ 🖋️</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Steps;
