import { findInvName, findInvConversion } from "../../lib/find";
import React, { useState } from "react";

// ---------------------------------------------------------------

const CardBack = ({ currInvArtikel, handleInputWaste }) => {
  const [digit, setDigit] = useState("0");
  const [currUnit, setCurrUnit] = useState(
    findInvConversion(findInvName(currInvArtikel)).conversion[0].text
  );

  //Keypad
  const keypad = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0", ",", "C"];

  const handleInput = (inputKeypad) => {
    if (digit.length < 5 || inputKeypad === "C") {
      switch (inputKeypad) {
        case "0":
          if (digit === "0") break;
          setDigit(digit + inputKeypad);
          break;
        case ",":
          if (!digit.includes(".")) setDigit(digit + ".");
          break;
        case "C":
          setDigit("0");
          break;
        default:
          if (digit === "0") setDigit(inputKeypad);
          else setDigit(digit + inputKeypad);
      }
    }
  };

  const finishInput = () => {
    const conversion = findInvConversion(
      findInvName(currInvArtikel)
    ).conversion.find((o) => o.text === currUnit);
    const result = Math.round(digit * conversion.factor * 1000) / 1000;
    handleInputWaste(result);
  };

  return (
    <div className="bg-black w-full rounded-b-2xl sm:rounded-none">
      <div className="w-full  text-white p-2 flex flex-row items-center justify-end text-4xl">
        <div className="pr-4 ">{digit}</div>

        <select
          className="bg-black text-white"
          onChange={(e) => setCurrUnit(e.target.value)}
        >
          {findInvConversion(findInvName(currInvArtikel)).conversion.map(
            (unit, index) => (
              <option key={index} value={unit.text}>
                {unit.text}
              </option>
            )
          )}
        </select>
      </div>
      <div className="w-full mx-auto justify-center items-center ">
        <div className="grid grid-cols-3 place-items-center gap-2  mb-2">
          {keypad.map((inputKeypad, idx) => (
            <div key={idx}>
              <div
                className={` text-white text-2xl h-[60px] w-[60px] flex justify-center items-center rounded-full my-2 ${
                  inputKeypad === "C" ? "bg-red-500" : "bg-neutral-500"
                }`}
                onClick={() => handleInput(inputKeypad)}
              >
                {inputKeypad}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row pb-4 justify-center w-full rounded-b-2xl md:rounded-none">
        <div
          className="rounded-full border border-white p-2 bg-green-900 text-white cursor-pointer text-center w-5/12"
          onClick={() => finishInput()}
        >
          ok
        </div>
      </div>
    </div>
  );
};

export default CardBack;
