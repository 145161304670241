import React from "react";
import { useSelector, useDispatch } from "react-redux";
import WasteTr from "./WasteTr";
import { nanoid } from "@reduxjs/toolkit";

// ---------------------------------------------------------------

const SummaryWaste = () => {
  const trashcan = useSelector((state) => state.waste.trashcan);
  const summaryArt = [];
  const sumWaste = [];

  // Zusammenfassung
  trashcan.map((waste) => {
    if (!summaryArt.includes(waste.invArt)) summaryArt.push(waste.invArt);
  });

  summaryArt.map((item) => {
    const selection = trashcan.filter(({ invArt }) => invArt === item);
    let sumAmount = 0;
    selection.map((waste) => {
      sumAmount += waste.invAmount;
    });

    const sumWasteElement = {
      id: nanoid(),
      invArt: selection[0].invArt,
      invName: selection[0].invName,
      invUnit: selection[0].invUnit,
      invAmount: sumAmount,
    };
    sumWaste.push(sumWasteElement);
  });

  return (
    <>
      {sumWaste.map(function (item, index) {
        return <WasteTr waste={item} key={index} />;
      })}
    </>
  );
};

export default SummaryWaste;
