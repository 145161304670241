import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import wasteReducer from "./wasteSlice";

// ---------------------------------------------------------------

export default configureStore({
  reducer: {
    counter: counterReducer,
    waste: wasteReducer,
  },
});
