import React from "react";
import { Route, Routes } from "react-router-dom";

import Signup from "../pages/Signup";
import Account from "../pages/Account";
import Home from "../pages/Home";
import WasteBoard from "../pages/WasteBoard";
import RecipeDetail from "../components/products/RecipeDetail";
import GroupDetail from "../components/groups/GroupDetail";
import NewsDetail from "../components/news/NewsDetail";
import WasteRecipe from "../components/waste/WasteRecipe";
import CreateRecipe from "../pages/CreateRecipe";

// ---------------------------------------------------------------

const UserRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/account" element={<Account />} />
        <Route path="/recipes/:recipeId" element={<RecipeDetail />} />
        <Route path="/groups/:groupId" element={<GroupDetail />} />
        <Route path="/newrecipe" element={<CreateRecipe />} />
        <Route path="/editrecipes/:recipeId" element={<CreateRecipe />} />
        <Route path="waste/:wasteId" element={<WasteRecipe />} />
        <Route path="news/:newsId" element={<NewsDetail />} />
        <Route path="/wasteboard" element={<WasteBoard />} />

        <Route path="/*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default UserRoutes;
