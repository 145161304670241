import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

// ---------------------------------------------------------------

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await createUser(email, password);
      navigate("/");
    } catch (e) {
      setError(e.message);
      alert(e.message);
    }
  };

  return (
    <div className="max-w-[700px] mx-auto my-16 p-4">
      <div className="bg-slate-300 p-2 border shadow-2xl rounded-xl">
        <div className="bg-slate-300 p-4 border border-slate-400 rounded-xl">
          <div>
            <h1 className="text-4xl font-bold pt-2 pb-6 text-center border-b border-slate-400 mb-4  text-slate-600">
              🔏 Registierung
            </h1>
            <p className="py-2">
              bereits registriert?
              <Link to="/" className="pl-2 underline">
                zur Anmeldung
              </Link>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col py-2">
              <label className="py-2 font-medium">Email Adresse</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="border p-3 rounded-lg text-black"
                type="email"
              />
            </div>
            <div className="flex flex-col py-2">
              <label className="py-2 font-medium">Passwort</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="border p-3 rounded-lg text-black"
                type="password"
              />
            </div>
            <button className="border border-blue-500 bg-blue-600 hover:bg-blue-500 w-full p-4 my-2 text-white mt-8 rounded-lg">
              registrieren
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
