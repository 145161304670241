import React from "react";
import { useParams } from "react-router-dom";
import Card from "../products/Card";
import {
  findBg,
  findProducts,
  findSymbol,
  promotedProducts,
} from "../../lib/find";

// ---------------------------------------------------

const GroupDetail = () => {
  const params = useParams();
  const groupName = params.groupId;
  const products =
    groupName === "aktionen"
      ? promotedProducts().sort((a, b) => (a.name > b.name ? 1 : -1))
      : findProducts(groupName).sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div className="max-w-[1800px] mx-auto px-4 md:px-16 my-16 md:my-8 flex flex-col items-between">
      <div
        className={`${findBg(
          groupName
        )} rounded-xl text-neutral-100 border border-slate-100`}
      >
        <h1 className="p-4 text-2xl">
          <span className="mr-2">{findSymbol(groupName)}</span>
          <span className="font-face-gm ">{groupName.toUpperCase()}</span>
        </h1>
        <div className="flex flex-row justify-center bg-slate-100 rounded-b-xl">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {products.map(function (product, index) {
              return <Card key={index} cardData={product} width="xl" />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetail;
