export const newsData = [
    {
      id: '1ads',
      topic: '1. News',
      icon: '📣',
      text: '1. Nachricht Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium, quo odio nostrum molestiae alias nisi quidem iusto deleniti labore asperiores. In, itaque! Modi, possimus quae at voluptatum officiis esse veritatis nobis ratione fugit dolore eum assumenda earum. Tenetur fuga expedita incidunt illo nisi vitae nulla porro sint tempore. Pariatur quia, non nostrum tempora placeat molestias cupiditate sequi exercitationem recusandae itaque fugiat earum eius, voluptate, dolores culpa. Distinctio fugit pariatur exercitationem ipsa, sunt necessitatibus eum eligendi quae maxime, expedita impedit, architecto unde adipisci quia accusantium odit neque? Exercitationem molestiae accusamus velit neque recusandae eum blanditiis asperiores id accusantium, aperiam nostrum ex?',
      style: 'bg-red-700',
      alert: true
    },
    {
      id: '2ss',
      topic: '2. News',
      icon: '🎉',
      text: 'Lorem ipsum, dolor s',
      style: 'bg-green-700',
      alert: true
      },
      {
        id: '3ss',
        topic: '3. News',
        icon: '📧',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium, quo odio nostrum molestiae alias nisi quidem iusto deleniti labore asperiores. In, itaque! Modi, possimus quae at voluptatum officiis esse veritatis nobis ratione fugit dolore eum assumenda earum. Tenetur fuga expedita incidunt illo nisi vitae nulla porro sint tempore. Pariatur quia, non nostrum tempora placeat molestias cupiditate sequi exercitationem recusandae itaque fugiat earum eius, voluptate, dolores culpa. Distinctio fugit pariatur exercitationem ipsa, sunt necessitatibus eum eligendi quae maxime, expedita impedit, architecto unde adipisci quia accusantium odit neque? Exercitationem molestiae accusamus velit neque recusandae eum blanditiis asperiores id accusantium, aperiam nostrum ex?',
        style: 'bg-blue-700',
        alert: true
      },
      {
        id: '4s',
        topic: '4. News',
        icon: '⚠️',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium, quo odio nostrum molestiae alias nisi quidem iusto deleniti labore asperiores. In, itaque! Modi, possimus quae at voluptatum officiis esse veritatis nobis ratione fugit dolore eum assumenda earum. Tenetur fuga expedita incidunt illo nisi vitae nulla porro sint tempore. Pariatur quia, non nostrum tempora placeat molestias cupiditate sequi exercitationem recusandae itaque fugiat earum eius, voluptate, dolores culpa. Distinctio fugit pariatur exercitationem ipsa, sunt necessitatibus eum eligendi quae maxime, expedita impedit, architecto unde adipisci quia accusantium odit neque? Exercitationem molestiae accusamus velit neque recusandae eum blanditiis asperiores id accusantium, aperiam nostrum ex?',
        style: 'bg-amber-700',
        alert: true
      },

]