import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { navData } from "../../data/navdata";
import NavLink from "./NavLink";

// ----------------------------------------------------------

const Navigation = () => {
  const { user, logout } = UserAuth();
  const [navbar, setNavbar] = useState(false);
  const items = navData;

  return (
    <nav className={`${user ? "block" : "hidden"}`}>
      <div
        className="w-full shadow-lg h-16"
        style={{
          background: "linear-gradient(to bottom,#051932 0%,#072a4c 100%)",
        }}
      >
        <div className="justify-between px-6 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between md:block">
              {/* LOGO */}
              <Link to="/">
                <img
                  src="/bilder/logo.png"
                  width={185}
                  height={68}
                  alt="logo"
                  className="absolute shadow-xl left-6 top-4"
                />
              </Link>

              {/* HAMBURGER BUTTON FOR MOBILE */}
              <div className="md:hidden">
                <button
                  className="p-4 text-gray-700 rounded-xl outline-none"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <></>
                  ) : (
                    <img
                      src="/bilder/hamburgermenue.svg"
                      width={30}
                      height={30}
                      alt="logo"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              className={`flex-1 justify-self-center pb-3 md:block md:pb-0 md:mt-0 ${
                navbar ? "p-8 md:p-0 block" : "hidden"
              }`}
            >
              <ul
                className="h-auto mt-8 md:mt-0  rounded-3xl md:h-14 items-center justify-center md:flex md:py-0 md:rounded-none"
                style={{
                  background:
                    "linear-gradient(to bottom,#051932 0%,#072a4c 100%)",
                }}
              >
                <>
                  {items.map(function (item, index) {
                    return (
                      <div key={index} onClick={() => setNavbar(!navbar)}>
                        <NavLink item={item} />
                      </div>
                    );
                  })}

                  <div className="md:none h-16 flex flex-row justify-center items-center">
                    <div
                      className="md:hidden w-full h-full flex flex-col justify-center items-center"
                      onClick={() => setNavbar(!navbar)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="48px"
                        height="48px"
                      >
                        <path
                          fill="none"
                          stroke="#f9fafb"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          stroke-width="3"
                          d="M35.4,38.8c-3.2,2.4-7.1,3.9-11.4,3.9C13.7,42.7,5.3,34.3,5.3,24c0-2.6,0.6-5.2,1.5-7.4"
                        />
                        <path
                          fill="none"
                          stroke="#f9fafb"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          stroke-width="3"
                          d="M12.1,9.6C15.3,7,19.5,5.3,24,5.3c10.3,0,18.7,8.4,18.7,18.7c0,2.3-0.4,4.5-1.2,6.6"
                        />
                        <line
                          x1="31.1"
                          x2="16.9"
                          y1="16.9"
                          y2="31.1"
                          fill="none"
                          stroke="#f9fafb"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          stroke-width="3"
                        />
                        <line
                          x1="31.1"
                          x2="16.9"
                          y1="31.1"
                          y2="16.9"
                          fill="none"
                          stroke="#f9fafb"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          stroke-width="3"
                        />
                      </svg>
                    </div>
                  </div>
                </>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
