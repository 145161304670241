import React from "react";

// ---------------------------------------------------------------

const WasteTr = ({ waste, handleEditWasteArt, index }) => {
  return (
    <tr
      className="border-b border-slate-300 bg-slate-100 mx-4 "
      onClick={() => handleEditWasteArt(index)}
    >
      <td className="py-2 border-r border-red-800">{waste.invArt}</td>
      <td className="py-2 text-left px-4 border-r border-red-800">
        {waste.invName}
      </td>
      <td className="py-2 border-r border-red-800">{waste.invAmount}</td>
      <td className="py-2">{waste.invUnit}</td>
    </tr>
  );
};

export default WasteTr;
