import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import "./fonts/BrandonPrintedTwo.ttf";
import store from "./redux/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorkerRegistration";

// ---------------------------------------------------------------

serviceWorker.register();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrowserRouter>
  </Provider>
);
