import { navData } from "../data/navdata";
import { productData } from "../data/productsData";
import { invData } from "../data/invData";
import { newsData } from "../data/newsData";

// ------- NEWS Function ----------
export function currNewsIndex(id) {
  const pos = newsData.map((e) => e.id).indexOf(id);
  return pos;
}

export function lengthNewsData() {
  return newsData.length;
}
export function findProducts(name) {
  const result = productData.filter(
    (o) => o.category.toUpperCase() === name.toUpperCase()
  );
  return result;
}

export function goNews(index) {
  return newsData[index].id;
}

export function findNews(id) {
  const result = newsData.filter((o) => o.id === id);
  return result;
}

// -------- inv Function ----------
export function findInvItems(id) {
  const result = productData.filter((o) => o.id === id);
  return result[0].inventoryItemNumbers;
}

export function findInvUnit(nr) {
  const result = invData.find((o) => o.nr === nr);
  return result.unit;
}

export function findInvName(nr) {
  const result = invData.find((o) => o.nr === nr);
  return result.name;
}

export function findInvNr(name) {
  const result = invData.find((o) => o.name === name);
  return result.nr;
}

export function findInvConversion(name) {
  const result = invData.find((o) => o.name === name);
  return result;
}

//----------- Product Function ----------
export function promotedProducts() {
  const result = productData.filter((o) => o.promotion === true);
  return result;
}

export function findBg(name) {
  const result = navData.find(
    (o) => o.name.toUpperCase() === name.toUpperCase()
  );
  return result?.style;
}

export function findSymbol(name) {
  const result = navData.find(
    (o) => o.name.toUpperCase() === name.toUpperCase()
  );
  return result?.icon;
}

export function findName(id) {
  const result = productData.find((o) => o.id === id);
  return result.name;
}

export function findProduct(id) {
  const result = productData.find((o) => o.id === id);
  return result;
}
