import React from "react";
import { navData } from "../data/navdata";
import { productData } from "../data/productsData";
import { findProducts, promotedProducts } from "../lib/find";
import News from "../components/news/News";
import GroupCard from "../components/groups/GroupCard";

// ---------------------------------------------------------------

const Home = () => {
  const items = navData;
  const products = productData;
  const displayNews = false;

  return (
    <div className="max-w-[1800px] mx-auto px-4 md:px-16 mt-16 flex flex-col items-between">
      {displayNews && <News />}
      {navData.map(function (item, index) {
        if (item.name === "Aktionen") {
          return (
            <div key={index}>
              <GroupCard item={item} data={promotedProducts()} />
            </div>
          );
        }
        if (findProducts(item.name).length > 0) {
          return (
            <div key={index}>
              <GroupCard item={item} data={findProducts(item.name)} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default Home;
