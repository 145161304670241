export const navData = [
  {
    url: "aktionen",
    icon: "📣",
    name: "Aktionen",
    style: "bg-red-800",
  },
  {
    url: "snacks",
    icon: "🍤",
    name: "Snacks",
    style: "bg-zinc-800",
  },
  {
    url: "gerichte",
    icon: "🍽️",
    name: "Gerichte",
    style: "bg-yellow-950",
  },
  {
    url: "gofish",
    icon: "🐟",
    name: "gofish",
    style: "bg-blue-100",
  },
  {
    url: "otacos",
    icon: "🌮",
    name: "otacos",
    style: "bg-violet-900",
  },
  {
    url: "getränke",
    icon: "🥤",
    name: "getränke",
    style: "bg-teal-950",
  },
];
