import React from "react";
import { Link } from "react-router-dom";
import { compare } from "../../lib/utils";
import Card from "../products/Card";

// --------------------------------------------------------------------------

const GroupCard = ({ item, data }) => {
  data.sort(compare);

  return (
    <div
      className={`w-full mb-8 rounded-2xl border border-slate-100 p-2 px-4 text-neutral-100 h-[500px]  ${item.style}`}
    >
      <Link
        to={`/groups/${item.name.toLowerCase()}`}
        className="flex flex-row text-2xl items-start justify-between border-b border-neutral-100 pb-2"
      >
        <div>
          <span className="mx-2 md:mx-4">{item.icon}</span>
          <span className="font-face-gm uppercase">{item.name}</span>
        </div>
        <div className="text-neutral-100 text-lg font-bold mr-0.5 mt-1">
          Übersicht
        </div>
      </Link>
      <div className="flex flex-row overflow-x-auto pb-4">
        {data.map(function (card, index) {
          return (
            <div className="mr" key={index}>
              <Card cardData={card} width="sm" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCard;
