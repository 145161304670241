import React from "react";
import Layout from "./components/layout/Layout";
import UserRoutes from "./routes/UserRoutes";
import NonUserRoutes from "./routes/NonUserRoutes";
import { UserAuth } from "./context/AuthContext";
import store from "./redux/store";
import { Provider } from "react-redux";

// ---------------------------------------------------------------

function App() {
  const { user } = UserAuth();
  return (
    <>
      <Layout>{user ? <UserRoutes /> : <NonUserRoutes />}</Layout>
    </>
  );
}

export default App;
