import React, { useState } from "react";
import { useform } from "react-form-hook";
import Select from "react-select";
import { invData } from "../../data/invData";

// ---------------------------------------------------------------

const SearchInvItem = ({ handleNewArtikel, handleCancel, currInput }) => {
  const [artikel, setArtikel] = useState("");

  const options = invData.map((item) => {
    return {
      value: item.nr,
      label: item.name,
    };
  });

  return <div className="flex justify-center items-center"></div>;
};

export default SearchInvItem;
