import React from 'react'
import Signin from "../pages/Signin";

import { Route, Routes } from "react-router-dom";


const NonUserRoutes = () => {
  return (
    <div>
         <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/*" element={<Signin />} />
          </Routes>
    </div>
  )
}

export default NonUserRoutes