import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import left from "../../asset/left.svg";
import right from "../../asset/right.svg";

// ---------------------------------------------------------------

const Slider = ({ sliderData }) => {
  //AktivElement
  const [activeSlider, setActiveSlider] = useState(0);
  const [sliderTime, setSliderTime] = useState(2000);

  // NextElement
  const clickNext = () => {
    activeSlider === sliderData.length - 1
      ? setActiveSlider(0)
      : setActiveSlider(activeSlider + 1);
  };

  // PrevElement
  const clickPrev = () => {
    activeSlider === 0
      ? setActiveSlider(sliderData.length - 1)
      : setActiveSlider(activeSlider - 1);
  };

  // Autmatisches sliden
  useEffect(() => {
    const timer = setTimeout(() => {
      clickNext();
    }, sliderTime);
    return () => {
      clearTimeout(timer);
    };
  }, [activeSlider]);

  //AktuellesElementSlider
  const elem = sliderData[activeSlider];
  const history = useNavigate();

  return (
    <div className="grid h-[35vh] md:h-[70vh] grid-cols-1 md:grid-cols-2 w-full mx-auto shadow-2xl rounded-3xl">
      <img
        src={elem.src}
        alt=""
        width={100}
        height={100}
        className="w-full h-[35vh] md:h-[70vh] object-cover rounded-tl-3xl md:rounded-bl-3xl rounded-tr-3xl md:rounded-tr-none"
      />
      <div className="w-full h-[35vh] md:h-[70vh] bg-slate-900 flex flex-col justify-between text-white md:rounded-tr-3xl rounded-bl-3xl md:rounded-bl-none rounded-br-3xl">
        <div className="p-4 overflow-y-auto">
          <h1 className="text-center font-bold text-2xl mb-4">
            {elem.title}. Schritt
          </h1>
          <p className="">{elem.desc}</p>
        </div>
        <div className="flex flex-row justify-between items-center bg-blue-950 p-4  rounded-bl-3xl rounded-br-3xl md:rounded-bl-none">
          <div
            className="cursor-pointer p-1 rounded border border-white text-white px-2 hover:text-slate-900 hover:bg-white "
            onClick={() => history(-1)}
          >
            schliessen
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="cursor-pointer mr-6" onClick={clickPrev}>
              <img src={left} alt="prev" />
            </div>
            <div className="cursor-pointer" onClick={clickNext}>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
