import React, { useState } from "react";
import SearchInvItem from "../waste/SearchInvItem";
import { findInvName, findInvNr } from "../../lib/find";

// --------------------------------------------------------------- !!!!!

const Ingriedence = ({ handleIngriedient }) => {
  const [isNewIngredient, setIsNewIngredient] = useState(false);
  const [currInput, setCurrInput] = useState("");
  const [inventoryItemNumbers, setInventoryItemNumbers] = useState([]);

  const handleNewArtikel = (value) => {
    setCurrInput(value);
    const invnr = findInvNr(value);
    if (invnr) {
      if (!inventoryItemNumbers.includes(invnr)) {
        setInventoryItemNumbers([...inventoryItemNumbers, invnr]);
        handleIngriedient([...inventoryItemNumbers, invnr]);
        setCurrInput("");
      } else {
        setCurrInput("");
      }
    }
    setIsNewIngredient(false);
  };

  const handleCancel = () => {
    setIsNewIngredient(false);
  };

  const handleDelIngriedient = (item) => {
    const result = inventoryItemNumbers.filter((o) => o !== item);
    setInventoryItemNumbers(result);
    handleIngriedient(result);
  };

  return (
    <div className="w-full flex flex-col-reverse md:flex-row justify-between items-start">
      <div className="flex flex-col p-2 bg-white rounded-xl justify-end items-end">
        {isNewIngredient ? (
          <div className="w-full flex flex-row justify-end px-4">
            <SearchInvItem
              handleNewArtikel={handleNewArtikel}
              handleCancel={handleCancel}
              currInput={currInput}
            />
          </div>
        ) : (
          <div className="flex flex-row justify-start items-between w-full">
            <div className="flex flex-row flex-wrap gap-1 justify-center items-center my-2">
              {inventoryItemNumbers.map((item, index) => (
                <div
                  key={index}
                  className="pl-2 py-1 border rounded-full bg-slate-600 text-white text-xs flex flex-row justify-between w-[180px] items-center"
                >
                  <div className="pl-2 w-[100px]">{findInvName(item)}</div>
                  <div
                    className="rounded-full h-[30px] w-[30px] border-2 text-slate-600 border-red-600 bg-red-200 ml-4 mr-1 flex flex-row justify-center items-center font-bold"
                    onClick={() => handleDelIngriedient(item)}
                  >
                    x
                  </div>
                </div>
              ))}
            </div>
            <div
              onClick={() => setIsNewIngredient(true)}
              className="text-xs h-[30px] w-[30px] rounded-xl bg-green-700 text-white flex justify-center items-center"
            >
              +
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ingriedence;
