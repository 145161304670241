import React from "react";
import { useState } from "react";

// ---------------------------------------------------------------

const CardFront = ({ handleCurrInvArtikel, currWaste }) => {
  const [currInput, setCurrInput] = useState(0);

  return (
    <div className="bg-slate-200 flex flex-col justify-between items-center border-b border-slate-700 w-full">
      <table className="w-full">
        <thead>
          <tr className="bg-slate-700 w-full text-slate-300 p-4 text-xs text-left ">
            <th className=" py-2 px-8 text-center hidden md:block w-1/4">
              InvArtNr
            </th>
            <th className="py-2 px-4  w-1/2 md:w-2/4">Bezeichnung</th>
            <th className="py-2 px-4  w-1/2 md:w-1/4">Menge</th>
          </tr>
        </thead>
        <tbody>
          {currWaste.map(function (item, index) {
            return (
              <tr
                key={index}
                className="border-b border-slate-300 bg-slate-100 mx-4 "
                onClick={() => handleCurrInvArtikel(item.invArt)}
              >
                <td className="p-4 border-r border-red-600 hidden md:block">
                  {item.invArt}
                </td>
                <td className="p-4 w-1/2 text-left">{item.invName}</td>
                <td className=" mr-4 border-l border-red-600 w-1/4">
                  <div className="px-2  flex flex-row justify-end ">
                    <div className="bg-gray-300 rounded ml-8 mr-4 w-[80px] text-right pr-2">
                      {item.invAmount}
                    </div>
                    <div className="rounded">{item.invUnit}</div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CardFront;
