import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { increment } from "../../redux/counterSlice";
import { addWaste } from "../../redux/wasteSlice";
import { findInvItems, findInvName, findInvUnit } from "../../lib/find";
import CardFront from "./CardFront";
import CardBack from "./CardBack";
import { UserAuth } from "../../context/AuthContext";

// ---------------------------------------------------------------

const WasteRecipe = () => {
  const params = useParams();
  const invItems = findInvItems(params.wasteId);
  const history = useNavigate();
  const [isFrontCard, setIsFrontCard] = useState(true);
  const [currInvArtikel, setCurrInvArtikel] = useState();

  const dispatch = useDispatch();
  const waste = [];

  invItems.map(function (item, index) {
    const wastePosition = {
      id: index,
      time: Date.now(),
      user: "gg",
      invArt: item,
      invName: findInvName(item),
      invUnit: findInvUnit(item),
      invAmount: 0,
    };
    waste.push(wastePosition);
    console.log(waste);
  });

  const [currWaste, setCurrWaste] = useState(waste);

  const handleCurrInvArtikel = (name) => {
    setCurrInvArtikel(name);
    handleSwitchCard();
  };

  const handleSwitchCard = () => {
    setIsFrontCard(!isFrontCard);
  };

  const handleInputWaste = (amount) => {
    setCurrWaste(
      currWaste.map((waste) => {
        if (waste.invArt === currInvArtikel) {
          return { ...waste, invAmount: amount };
        } else {
          return waste;
        }
      })
    );
    handleSwitchCard();
  };

  const handleDeletInput = () => {
    setCurrWaste(
      currWaste.map((waste) => {
        return { ...waste, invAmount: 0 };
      })
    );
    history(-1);
  };

  const handleBooked = () => {
    currWaste.map((waste) => {
      if (waste.invAmount > 0) {
        dispatch(addWaste(waste));
        dispatch(increment());
      }
    });
    history(-1);
  };

  return (
    <div className="max-w-full mx-auto my-9 md:my-8 p-4  text-white text-center">
      <div className="mb-8 rounded-2xl border-2 border-slate-800 text-slate-700  bg-stone-300 flex flex-col justify-center items-center md:mx-12">
        {/* Card Header */}
        <div className="flex flex-row text-2xl items-start justify-between p-2 px-4 border-b border-blue-900 pb-2 w-full">
          <div className="flex flex-row items-center">
            <div className="mx-2 rounded-full p-2 border-2 border-red-500 bg-slate-100 h-12 w-12 flex justify-center items-center">
              🗑️
            </div>
            <span className="uppercase">
              {isFrontCard ? (
                <span className="font-face-gm ">Abschriften</span>
              ) : (
                <span className="text-base md:text-2xl">
                  {findInvName(currInvArtikel).substring(0, 25)}
                </span>
              )}
            </span>
            <span className="text-xs ml-2"></span>
          </div>
        </div>

        {/* Card Content */}
        <div className="w-full flex justify-center bg-black rounded-b-xl">
          {isFrontCard ? (
            <CardFront
              invItems={invItems}
              handleCurrInvArtikel={handleCurrInvArtikel}
              currWaste={currWaste}
            />
          ) : (
            <CardBack
              currInvArtikel={currInvArtikel}
              handleInputWaste={handleInputWaste}
            />
          )}
        </div>

        {/* Card Footer */}
        {isFrontCard ? (
          <div className="w-full my-4 flex flex-row  justify-between px-4">
            <button
              className="bg-red-500 text-white  rounded-xl border border-slate-800 flex flex-col justify-center items-center px-2"
              onClick={() => handleDeletInput()}
            >
              abbruch
            </button>
            <div className="w-1/4"></div>
            {/*onClick sende WasteArray an database, mit nur wenn MENGE <> 0 IST MIT TIMESTAMP  */}
            <button
              className="w-2/4 bg-green-800 text-white px-8 rounded-xl border border-green-300 p-4"
              onClick={() => handleBooked()}
            >
              buchen
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default WasteRecipe;
